<template>
  <div class="box">
    <div class="time">{{ time }}</div>
    <van-empty v-if="list.length == 0" class="custom-image" :image="noData" description="暂无测额产品" />
    <div class="product-box">
      <van-row class="list-title">
        <van-col span="9">建议办理顺序</van-col>
        <van-col span="6">本次预估额度</van-col>
        <van-col span="9">产品推荐星级</van-col>
      </van-row>
      <van-row class="list" v-for="item in list" :key="item.name" @click="toInfo(item.productId)">
        <van-col span="9">
          <div class="list-name">{{ item.name }}</div>
        </van-col>
        <van-col span="6"><span class="red" style="font-weight: bold; font-size: 14rpx">{{item.productState==0&&item.quotaW>10?item.quotaW:'不足10' }}万</span>
        </van-col>
        <van-col span="9"><van-rate v-model="item.recommendLevel" allow-half  color="#F7D600" size="14" readonly /></van-col>
      </van-row>
    </div>
    <div class="button-box">
      <div @click="toCompany">查看企业信息</div>
      <div @click="toMeasurement">再次测算</div>
    </div>
  </div>
</template>

<script>
  import url from "@/service/url-config";
  import Vue from "vue";
  import {
    Dialog
  } from "vant";
  Vue.use(Dialog);
  export default {
    data() {
      return {
        loanPredictionId: "",
        companyId: "",
        time: "",
        active: 0,
        list: [],
        noData: require("@/img/no-data.png"),
      };
    },
    created() {
      this.loanPredictionId = this.$route.query.loanPredictionId || 10380;
      this.companyId = this.$route.query.companyId;
      this.time = this.$route.query.time;
      this.getAfterAuth(this.loanPredictionId);
    },
    methods: {
      //获取匹配成功/未成功产品 productState :0成功 1失败
      getAfterAuth(loanPredictionId) {
        this.$http
          .get( url.afterAuth +loanPredictionId )
          .then((res) => {
           console.log(res,'----');
           if(res.code==200)
           this.list=res.data
          });
      },
      //失败原因
      cause(row) {
        this.$http
          .get(
            url.failureCause +
            `?loanPredictionId=${row.loanPredictionId}&productId=${row.productId}`
          )
          .then((res) => {
            let object = res.data;
            for (const key in object) {
              if (Object.hasOwnProperty.call(object, key)) {
                if (
                  !object[key] ||
                  key == "gmtCreate" ||
                  key == "gmtModified" ||
                  key == "loanPredictionId" ||
                  key == "productId" ||
                  key == "loanPredictionProductResultId" ||
                  key == "userIdCreate" ||
                  key == "userIdModified"
                ) {
                  delete object[key];
                }
                if (object.other) {
                  let otherArr = object.other.split(",").filter((item) => item); //去空/null ,undefined
                  if (otherArr.length > 0) {
                    otherArr.forEach((item, index) => {
                      object["other" + index] = item;
                    });
                  }
                  delete object["other"];
                }
              }
            }
            let cause = "";
            Object.keys(object).forEach((key, index) => {
              cause = cause + (index + 1) + ". " + object[key] + "\n";
            });
            Dialog.alert({
              message: cause,
              messageAlign: "left",
              theme: "round-button",
            });
          });
      },
      //产品详情
      toInfo(id) {
        this.$router.push({
          path: "productInfo",
          query: {
            id: id,
            type: "putong",
          },
        });
      },
      toMeasurement() {
        this.$router.push("/measurement");
      },
      toCompany() {
        this.$router.push({
          path: "company",
          query: {
            companyId: this.companyId,
          },
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .box {
    background: #f4f5f9;
    // min-height: calc(100vh - 80px);
    // padding-bottom: 30px;
    // min-height: 100vh;
    padding-top: 66px;
    min-height: calc(100vh - 66px);

    .time {
      background: #ffffff;
      font-size: 14px;
      color: #0084ff;
      width: 100%;
      height: 44px;
      text-align: center;
      line-height: 44px;
      border-bottom: 1px solid #f3f3f3;
    }

    .tabs /deep/ .van-sticky {
      height: 50px;
      background: #ffffff;
    }

    .product-box {
      width: 355px;
      margin: 0 auto;
      margin-top: 15px;
      border-radius: 5px;
      background: #ffffff;
    }

    .product {
      width: calc(355px - 20px);
      height: calc(140px - 34px);
      border-bottom: 1px solid #f3f3f3;
      padding: 17px 10px;
    }

    .list-title {
      height: 42px;
      color: #555555;
      font-size: 12px;
      line-height: 42px;
      text-align: center;
      border-bottom: 1px solid #ededed;
    }

    .list {
      height: 42px;
      color: #555555;
      font-size: 12px;
      line-height: 42px;
      text-align: center;
      border-bottom: 1px solid #ededed;
    }

    .list-name {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .red {
      color: #ef5446;
    }

    .blue {
      color: #1377ff;
    }

    .button-box {
      width: 100%;
      margin-top: 62px;
      padding-bottom: 30px;
      display: flex;
      justify-content: space-around;

      div {
        width: 142px;
        height: 42px;
        background: #1377ff;
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 42px;
      }
    }
  }
</style>